import { useFormatPrice } from 'hooks/formatting/useFormatPrice';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { FC } from 'react';
import { twJoin } from 'tailwind-merge';
import { ProductPriceType } from 'types/price';
import twMerge from 'utils/twMerge';

const TEST_IDENTIFIER = 'blocks-product-price';

type ProductPriceProps = {
    type?: 'detail' | 'mediumSize';
    secondaryPrice?: 'smallFont';
};

export const ProductPrice: FC<ProductPriceType & ProductPriceProps> = (props) => {
    const t = useTypedTranslationFunction();
    const formatPrice = useFormatPrice();

    const productPriceInfo_twClass = twMerge(
        'inline-block font-base text-extraVerySmall text-base',
        props.type === 'detail' && 'text-default',
        props.type === 'mediumSize' && 'text-extraSmall',
    );
    const productUnitPrice_twClass = twMerge(
        'block w-full mb-[6px] text-extraSmall text-grey font-base',
        props.type === 'detail' && 'leading-4 text-small',
        'last:mb-0',
    );

    return (
        <div className="flex flex-col flex-wrap items-start">
            <div
                className={twMerge(
                    'relative mb-2 text-small font-bold leading-4 text-base',
                    'after:absolute after:bottom-[-5px] after:left-0 after:block after:h-[2px] after:w-full after:bg-orange after:content-[""]',
                    props.type === 'detail' && 'mb-[18px] text-big after:bottom-[-11px] after:h-1',
                    props.type === 'mediumSize' && 'mb-3 text-default after:bottom-[-6px] after:h-[3px]',
                )}
                data-testid={TEST_IDENTIFIER + '-without-vat'}
            >
                {props.isPriceFrom && t('From') + '\u00A0'}
                {formatPrice(props.priceWithoutVat)}&nbsp;
                <div className={productPriceInfo_twClass}>{t('excl. VAT')}</div>
            </div>
            <div
                className={twMerge(
                    'text-small font-bold leading-4 text-base',
                    props.type === 'detail' && 'text-big',
                    props.secondaryPrice && 'text-littleSmall font-base',
                    props.type === 'mediumSize' && 'text-default',
                )}
                data-testid={TEST_IDENTIFIER + '-with-vat'}
            >
                {props.isPriceFrom && t('From') + '\u00A0'}
                {formatPrice(props.priceWithVat)}&nbsp;
                <div className={productPriceInfo_twClass}>{t('incl. VAT')}</div>
            </div>
            {props.unitPrice !== null && (
                <>
                    <div
                        className={twJoin(productUnitPrice_twClass, props.type === 'detail' ? 'mt-3' : 'mt-[6px]')}
                        data-testid={TEST_IDENTIFIER + '-unit-without-vat'}
                    >
                        {formatPrice(props.unitPrice.priceWithoutVat)}/{props.stockUnitName}
                        &nbsp;{t('excl. VAT')}
                    </div>
                    <div className={productUnitPrice_twClass} data-testid={TEST_IDENTIFIER + '-unit-with-vat'}>
                        {formatPrice(props.unitPrice.priceWithVat)}/{props.stockUnitName}
                        &nbsp;{t('incl. VAT')}
                    </div>
                </>
            )}
        </div>
    );
};

ProductPrice.displayName = 'ProductPrice';
