import { ListedProductType, ProductDetailType, VariantDetailType } from 'types/product';
import { AddToCartPopupDataType, ProductCartItemType } from 'types/cart';
import { ListedProductAccessoriesFragmentApi } from 'graphql/generated';

export const isProductToDelivery = (
    product:
        | ListedProductType
        | AddToCartPopupDataType
        | ProductCartItemType
        | ProductDetailType
        | VariantDetailType
        | ListedProductAccessoriesFragmentApi,
): boolean => {
    return product.availability.status !== 'immediately' && product.stockQuantity < 1 && (product.tdk ?? 0) > 1;
};
