import { CompareIcon, HeartFullIcon, HeartIcon } from 'components/Basic/Icon/IconsSvg';
import { useHandleCompare } from 'hooks/product/useHandleCompare';
import { useHandleProductInWishlist } from 'hooks/wishlist/useHandleProductInWishlist';
import { FC, HTMLAttributes } from 'react';
import twMerge from 'utils/twMerge';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import dynamic from 'next/dynamic';
import { ProductButtonsActionPopupPropsType } from 'components/Blocks/Popup/ProductButtonsActionPopup/ProductButtonsActionPopup';
import { gtmElementIds } from 'config/constants';
import { getCatalogNumberFromProduct } from 'helpers/product/getCatalogNumber';
import { ActionButtonProductType } from 'types/product';

const ProductButtonsActionPopup = dynamic<ProductButtonsActionPopupPropsType>(
    () =>
        import('components/Blocks/Popup/ProductButtonsActionPopup/ProductButtonsActionPopup').then(
            (component) => component.ProductButtonsActionPopup,
        ),
    { ssr: false },
);

type NativeProps = ExtractNativePropsFromDefault<HTMLAttributes<HTMLDivElement>, never, 'className'>;

type ButtonsActionProps = {
    product: ActionButtonProductType;
    hideCompare?: boolean;
};

const TEST_IDENTIFIER = 'buttons-action';

export const ButtonsAction: FC<ButtonsActionProps & NativeProps> = ({ product, className, hideCompare }) => {
    const catalogNumber = getCatalogNumberFromProduct(product);

    const {
        isProductInCompare,
        removeProductFromCompare,
        addProductToCompare,
        isPopupCompareOpen,
        setIsPopupCompareOpen,
    } = useHandleCompare(catalogNumber);
    const { isProductInWishlist, handleProductInWishlist, isPopupWishlistOpen, setIsPopupWishlistOpen } =
        useHandleProductInWishlist(product.uuid);

    return (
        <>
            <div className={twMerge('mb-2 flex justify-end', className)} data-testid={TEST_IDENTIFIER}>
                <div className="mr-2 flex cursor-pointer">
                    {isProductInWishlist ? (
                        <HeartFullIcon
                            onClick={handleProductInWishlist}
                            className={'text-green'}
                            id={gtmElementIds.WISHLIST_PRODUCT_LIST}
                        />
                    ) : (
                        <HeartIcon
                            onClick={handleProductInWishlist}
                            className={'text-grey'}
                            id={gtmElementIds.WISHLIST_PRODUCT_LIST}
                        />
                    )}
                </div>
                {!hideCompare && (
                    <div className="mr-2 flex cursor-pointer last:mr-0">
                        <CompareIcon
                            onClick={isProductInCompare ? removeProductFromCompare : addProductToCompare}
                            className={twMerge('text-grey', isProductInCompare && 'text-green')}
                            id={gtmElementIds.COMPARE_PRODUCT_LIST}
                        />
                    </div>
                )}
            </div>

            {(isPopupCompareOpen || isPopupWishlistOpen) && (
                <ProductButtonsActionPopup
                    onCloseCallback={
                        isPopupCompareOpen ? () => setIsPopupCompareOpen(false) : () => setIsPopupWishlistOpen(false)
                    }
                    type={isPopupCompareOpen ? 'compare' : 'wishlist'}
                />
            )}
        </>
    );
};

ButtonsAction.displayName = 'ButtonsAction';
