import { gtmSafePushEvent } from 'helpers/gtm/gtm';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { GtmPageViewEventType } from 'types/gtm/events';
import { getUrlWithoutGetParameters } from 'utils/getUrlWithoutGetParameters';
import { useGtmContext } from './context/useGtmContext';

export const useGtmPageViewEvent = (event: GtmPageViewEventType, fetching?: boolean): void => {
    const router = useRouter();
    const slug = getUrlWithoutGetParameters(router.asPath);
    const lastViewedSlug = useRef<string | undefined>(undefined);
    const toggleDidPageViewRun = useGtmContext().toggleDidPageViewRun;

    useEffect(() => {
        if (event._isLoaded && lastViewedSlug.current !== slug && fetching !== true) {
            lastViewedSlug.current = slug;
            gtmSafePushEvent(event);
            toggleDidPageViewRun(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event, fetching, slug]);
};
