import { FlagDisplayTypeEnumApi, ImageApi, SimpleFlagFragmentApi } from 'graphql/generated';
import { FC } from 'react';
import tinycolor from 'tinycolor2';
import twMerge from 'utils/twMerge';
import { twJoin } from 'tailwind-merge';
import Image from '../../Basic/Image';

type ProductFlagsProps = {
    flags: SimpleFlagFragmentApi[];
    staticPosition?: boolean;
    discountPercent: number;
    isProductList?: boolean;
};

const TEST_IDENTIFIER = 'blocks-product-flags-';

export const ProductFlags: FC<ProductFlagsProps> = (props) => {
    if (props.flags.length === 0) {
        return null;
    }

    return (
        <div
            className={twMerge(
                'pointer-events-none absolute top-[10px] z-above-11 mb-[-6px] flex flex-col flex-wrap p-0',
                props.isProductList && 'flex-row',
                props.staticPosition && 'sm:static sm:-mt-2 sm:flex sm:w-full sm:flex-wrap sm:pb-[10px]',
                props.staticPosition && 'md:absolute md:top-[10px] md:mt-0 md:p-0',
            )}
        >
            {props.flags.map((flag, key) => {
                const flagImage = (flag.flagImages[0] ?? null) as ImageApi | null;
                const showIconTypes = [FlagDisplayTypeEnumApi.IconApi, FlagDisplayTypeEnumApi.IconAndTextApi];
                const showTextTypes = [FlagDisplayTypeEnumApi.TextApi, FlagDisplayTypeEnumApi.IconAndTextApi];
                const shouldShowIcon = flagImage !== null && showIconTypes.includes(flag.displayType);
                const shouldShowText = flagImage === null || showTextTypes.includes(flag.displayType);

                return (
                    <div
                        key={key}
                        className={twMerge(
                            'order-1 mb-[6px] mr-[6px] inline-flex self-start rounded-e-md px-3 py-2 text-extraSmall font-bold uppercase leading-4 text-purpleLight no-underline',
                            !flag.textColor && tinycolor(flag.rgbColor).isLight() ? 'text-black' : 'text-white',
                            flag.isDiscountFlag && 'order-2 mr-[100%] flex-col text-center',
                            props.isProductList && 'px-[10px] py-[1px] font-semiBold',
                            flag.isDiscountFlag && props.isProductList && 'px-[7px] py-[4px]',
                            shouldShowIcon && 'py-[1px] pl-0',
                            !shouldShowText && '!pr-0',
                        )}
                        style={{ color: flag.textColor, backgroundColor: flag.rgbColor }}
                        data-testid={TEST_IDENTIFIER + key}
                    >
                        {shouldShowIcon && (
                            <Image
                                src={flagImage.url}
                                alt={flagImage.name ?? ''}
                                loading="eager"
                                width={36}
                                height={28}
                                className={twJoin('mb-[-1px] mt-[-1px] object-fill', shouldShowText && 'mr-2')}
                            />
                        )}
                        {shouldShowText && (
                            <>
                                {flag.isDiscountFlag && (
                                    <span
                                        className={twMerge(
                                            'text-h3 font-bold leading-[19px]',
                                            props.isProductList && 'text-extraSmall leading-[14px]',
                                        )}
                                    >{`${Math.max(1, Math.round(props.discountPercent))}%`}</span>
                                )}
                                <span
                                    className={twJoin(
                                        flag.isDiscountFlag && 'leading-[10px]',
                                        flag.isDiscountFlag && props.isProductList && 'font-semiBold',
                                        shouldShowIcon && 'leading-7',
                                    )}
                                >
                                    {flag.name}
                                </span>
                            </>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

ProductFlags.displayName = 'ProductFlags';
