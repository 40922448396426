import { AddToCart } from 'components/Blocks/Product/AddToCart/AddToCart';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useRouter } from 'next/dist/client/router';
import { FC } from 'react';
import { GtmListNameType, GtmMessageOriginType } from 'types/gtm/enums';
import { Button } from 'components/Forms/Button/Button';
import { ListedProductAccessoriesFragmentApi } from 'graphql/generated';
import { ListedProductType } from 'types/product';
import { AddToCartButtonVariantType } from 'types/cart';

type ProductActionProps = {
    product: ListedProductType | ListedProductAccessoriesFragmentApi;
    gtmListName: GtmListNameType;
    gtmMessageOrigin: GtmMessageOriginType;
    listIndex: number;
    dropdownIndex?: number;
    hideContentOnClick?: boolean;
    buttonVariant?: AddToCartButtonVariantType;
    chooseVariantEventClick?: () => void;
};

export const ProductAction: FC<ProductActionProps> = (props) => {
    const testIdentifier = 'blocks-product-action';
    const router = useRouter();
    const t = useTypedTranslationFunction();

    if (props.product.imosConfigurable) {
        const productHref = props.product.slug;

        return (
            <Button
                type="button"
                size="small"
                variant="primary"
                onClick={() => {
                    if (props.chooseVariantEventClick) {
                        props.chooseVariantEventClick();
                    }
                    router.push(productHref + '#custom-configuration');
                }}
                onClickLoading
                name="custom-configuration"
                data-testid={testIdentifier + '-custom-configuration'}
                className="max-w-[110px] px-2 py-[5px] text-extraSmall"
            >
                {t('Custom configuration')}
            </Button>
        );
    }

    if (props.product.__typename === 'MainVariant') {
        const productHref = props.product.slug;

        return (
            <Button
                type="button"
                size="small"
                variant="primary"
                onClick={() => {
                    if (props.chooseVariantEventClick) {
                        props.chooseVariantEventClick();
                    }
                    router.push(productHref);
                }}
                onClickLoading
                name="choose-variant"
                data-testid={testIdentifier + '-choose-variant'}
                className="max-w-[100px] px-2 py-[5px] text-littleSmall"
            >
                {t('Choose variant')}
            </Button>
        );
    }

    if (props.product.isSellingDenied) {
        return <p className="m-0 w-full text-extraSmall">{t('This item can no longer be purchased')}</p>;
    }

    return (
        <AddToCart
            catalogNumber={props.product.catalogNumber}
            productUuid={props.product.uuid}
            productName={props.product.fullName}
            minQuantity={1}
            variant={props.buttonVariant}
            isSpinboxHidden={true}
            gtmListName={props.gtmListName}
            gtmMessageOrigin={props.gtmMessageOrigin}
            listIndex={props.listIndex}
            dropdownIndex={props.dropdownIndex}
            hideContentOnClick={props.hideContentOnClick}
            productSlug={props.product.slug}
        />
    );
};

ProductAction.displayName = 'ProductAction';
