import { useFormatPrice } from 'hooks/formatting/useFormatPrice';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { FC } from 'react';
import { twJoin } from 'tailwind-merge';
import { ProductPriceType } from 'types/price';
import twMerge from 'utils/twMerge';

export const DiscountPrice: FC<ProductPriceType> = (props) => {
    const t = useTypedTranslationFunction();
    const formatPrice = useFormatPrice();

    if (props.recommendedPrice === null || props.discountPrice <= 1) {
        return null;
    }

    return (
        <div className={twJoin('flex flex-row flex-wrap items-start', props.withGap && 'mb-1')}>
            <div
                className={twMerge(
                    'pr-[5px] text-small font-semiBold text-base',
                    props.smallVariant && 'text-extraSmall',
                )}
            >
                <span className="line-through">{formatPrice(props.recommendedPrice)}</span>&nbsp;
                {!props.smallVariant && <>{t('excl. VAT')}</>}
            </div>
            <div className={twMerge('text-small font-semiBold text-red', props.smallVariant && 'text-extraSmall')}>
                {t('you save')} {formatPrice(props.discountPrice)}
            </div>
        </div>
    );
};

DiscountPrice.displayName = 'DiscountPrice';
