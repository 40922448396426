import SeoMeta from 'components/Basic/Head/SeoMeta';

type LayoutProps = {
    title?: string | null;
    description?: string | null;
};

/**
 * Basic page layout for common pages
 */
const CommonLayout: FC<LayoutProps> = (props) => {
    return (
        <>
            <SeoMeta title={props.title} description={props.description} />
            {props.children}
        </>
    );
};

/* @component */
export default CommonLayout;
