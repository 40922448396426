import { showErrorMessage, showInfoMessage } from 'components/Helpers/Toasts';
import { mapCart, mapCartItem, useCurrentCart } from 'connectors/cart/Cart';
import { addProductIntoCart } from 'connectors/cart/CartCacheExchange';
import { CartTypeEnumApi, useAddToCartMutationApi } from 'graphql/generated';
import { onGtmChangeCartItemEventHandler } from 'helpers/gtm/eventHandlers';
import { getGtmMappedCart } from 'helpers/gtm/gtm';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { useCurrentUserData } from 'hooks/user/useCurrentUserData';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { GtmFlowType, GtmListNameType, GtmMessageOriginType } from 'types/gtm/enums';
import { dispatchBroadcastChannel } from 'hooks/useBroadcastChannel';

export const useAddToCart = (origin: GtmMessageOriginType): typeof addToCartAction => {
    const [, addToCart] = useAddToCartMutationApi();
    const updateUserState = usePersistStore((s) => s.updateUserState);
    const cartUuid = usePersistStore((s) => s.cartUuid);
    const domainConfig = useDomainConfig();
    const t = useTypedTranslationFunction();
    const { cart, resultDataCart } = useCurrentCart();
    const { isUserLoggedIn } = useCurrentUserData();

    const addToCartAction = async (
        productUuid: string | null,
        catalogNumber: string | null,
        listIndex: number,
        quantity: number,
        gtmListName: GtmListNameType,
        productServiceUuids?: string[],
        isAbsoluteQuantity = false,
        imosSessionId: string | null = null,
    ) => {
        const itemToBeAdded = cart?.items.find(
            (item) => item.product.uuid === productUuid || item.product.catalogNumber === catalogNumber,
        );
        const initialQuantity = itemToBeAdded?.quantity ?? 0;
        const getProductServiceUuids = () => {
            if (!productServiceUuids) {
                return !itemToBeAdded ? [] : itemToBeAdded.selectedProductServices.map((service) => service.uuid);
            }
            return productServiceUuids;
        };
        const addToCartActionResult = await addToCart({
            cartUuid,
            productUuid,
            catalogNumber,
            quantity,
            isAbsoluteQuantity,
            cartType: CartTypeEnumApi.CartApi,
            productServiceUuids: getProductServiceUuids(),
            imosSessionId,
        });

        if (cartUuid === null && !isUserLoggedIn) {
            updateUserState({ cartUuid: addToCartActionResult.data?.AddToCart.cart.uuid });
        }

        // EXTEND ADDING TO CART HERE

        if (addToCartActionResult.error !== undefined) {
            showErrorMessage(t('Unable to add product to cart'), origin);
            return null;
        }

        const addToCartResult = addToCartActionResult.data?.AddToCart;

        if (addToCartResult === undefined) {
            return null;
        }

        dispatchBroadcastChannel({ name: 'refetchCart' });

        const addedCartItem = addToCartResult.addProductResult.cartItem;
        const notOnStockQuantity = addToCartResult.addProductResult.notOnStockQuantity;

        if (notOnStockQuantity > 0) {
            showErrorMessage(
                t(
                    'You have the maximum available amount in your cart, you cannot add more (total {{ quantity }} {{ unitName }})',
                    {
                        quantity: addedCartItem.quantity,
                        unitName: addedCartItem.product.unit.name,
                    },
                ),
                origin,
            );
        }

        const quantityDifference = isAbsoluteQuantity
            ? addToCartResult.addProductResult.addedQuantity - initialQuantity
            : addToCartResult.addProductResult.addedQuantity;
        const absoluteEventValue =
            Number.parseFloat(addedCartItem.product.price.priceWithoutVat) * Math.abs(quantityDifference);
        const absoluteEventValueWithTax =
            Number.parseFloat(addedCartItem.product.price.priceWithVat) * Math.abs(quantityDifference);

        const mappedCart = mapCart(
            {
                ...addToCartResult.cart,
                items: addProductIntoCart(resultDataCart ?? null, addToCartResult),
            },
            domainConfig.currencyCode,
        );
        onGtmChangeCartItemEventHandler(
            mapCartItem(addedCartItem, domainConfig.currencyCode),
            domainConfig.currencyCode,
            absoluteEventValue,
            absoluteEventValueWithTax,
            listIndex,
            quantityDifference,
            gtmListName,
            GtmFlowType.order,
            domainConfig.url,
            getGtmMappedCart(mappedCart, addToCartResult.cart.promoCode, isUserLoggedIn, domainConfig, cartUuid),
        );

        if (addToCartResult.cart.modifications.message) {
            showInfoMessage(addToCartResult.cart.modifications.message);
        }

        return addToCartResult;
    };

    return addToCartAction;
};
