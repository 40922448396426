import { PlusIcon, MinusIcon } from 'components/Basic/Icon/IconsSvg';
import { useForwardedRef } from 'hooks/typescript/UseForwardedRef';
import { FormEventHandler, forwardRef, HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import twMerge from 'utils/twMerge';

type NativeProps = ExtractNativePropsFromDefault<HTMLAttributes<HTMLDivElement>, never, 'className'>;

type SpinboxProps = {
    min: number;
    step: number;
    defaultValue: number;
    spinboxBigger?: boolean;
    onChangeValueCallback?: (currentValue: number) => void;
};

export const Spinbox = forwardRef<HTMLInputElement, SpinboxProps & NativeProps>(
    ({ min, onChangeValueCallback, step, className, spinboxBigger, ...restProps }, spinboxForwardedRef) => {
        const testIdentifier = 'forms-spinbox-';

        const [isHoldingDecrease, setIsHoldingDecrease] = useState(false);
        const [isHoldingIncrease, setIsHoldingIncrease] = useState(false);
        const intervalRef = useRef<NodeJS.Timer | null>(null);
        const spinboxRef = useForwardedRef(spinboxForwardedRef);

        const setNewSpinboxValue = useCallback(
            (newValue: number) => {
                if (Number.isNaN(newValue) || newValue < min) {
                    spinboxRef.current.valueAsNumber = min;
                } else {
                    spinboxRef.current.valueAsNumber = newValue;
                }

                if (onChangeValueCallback !== undefined) {
                    onChangeValueCallback(spinboxRef.current.valueAsNumber);
                }
            },
            [min, onChangeValueCallback, spinboxRef],
        );

        const onChangeValueHandler = useCallback(
            (amountChange: number) => {
                if (spinboxRef.current !== null) {
                    setNewSpinboxValue(spinboxRef.current.valueAsNumber + amountChange);
                }
            },
            [setNewSpinboxValue, spinboxRef],
        );

        useEffect(() => {
            if (isHoldingDecrease) {
                intervalRef.current = setInterval(() => {
                    onChangeValueHandler(-step);
                }, 200);
            } else {
                clearSpinboxInterval(intervalRef.current);
            }
            return () => {
                clearSpinboxInterval(intervalRef.current);
            };
        }, [isHoldingDecrease, onChangeValueHandler, step]);

        useEffect(() => {
            if (isHoldingIncrease) {
                intervalRef.current = setInterval(() => {
                    onChangeValueHandler(step);
                }, 200);
            } else {
                clearSpinboxInterval(intervalRef.current);
            }
            return () => {
                clearSpinboxInterval(intervalRef.current);
            };
        }, [isHoldingIncrease, onChangeValueHandler, step]);

        const clearSpinboxInterval = (interval: NodeJS.Timer | null) => {
            if (interval !== null) {
                clearInterval(interval);
            }
        };

        const onInputHandler: FormEventHandler<HTMLInputElement> = (event) => {
            if (spinboxRef.current !== null) {
                setNewSpinboxValue(event.currentTarget.valueAsNumber);
            }
        };

        const SpinboxButton_twClass =
            'text-base cursor-pointer text-[24px] bg-none border-0 outline-0 flex justify-center items-center w-8 p-0 min-h-0 ';

        return (
            <div
                className={twMerge(
                    'inline-flex w-[120px] overflow-hidden rounded-md bg-greyVeryLight',
                    spinboxBigger ? 'h-[54px]' : 'h-[48px]',
                    className,
                )}
            >
                <span
                    className={twJoin(SpinboxButton_twClass, 'order-1')}
                    onClick={() => onChangeValueHandler(-step)}
                    onMouseDown={() => setIsHoldingDecrease(true)}
                    onMouseUp={() => setIsHoldingDecrease(false)}
                    onMouseLeave={() => setIsHoldingDecrease(false)}
                    data-testid={testIdentifier + 'decrease'}
                >
                    <MinusIcon />
                </span>
                <span
                    className={twJoin(SpinboxButton_twClass, 'order-3')}
                    onClick={() => onChangeValueHandler(step)}
                    onMouseDown={() => setIsHoldingIncrease(true)}
                    onMouseUp={() => setIsHoldingIncrease(false)}
                    onMouseLeave={() => setIsHoldingIncrease(false)}
                    data-testid={testIdentifier + 'increase'}
                >
                    <PlusIcon dataTestid="basic-icon-iconsvg-Plus" />
                </span>
                <input
                    className={twJoin(
                        'order-2 h-full min-w-0 flex-1 border-0 bg-greyVeryLight p-0 text-center text-default font-base text-base outline-none',
                        '[&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:w-appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:w-appearance-none',
                    )}
                    ref={spinboxRef}
                    defaultValue={restProps.defaultValue}
                    onInput={onInputHandler}
                    type="number"
                    min={min}
                    data-testid={testIdentifier + 'input'}
                />
            </div>
        );
    },
);

Spinbox.displayName = 'Spinbox';
