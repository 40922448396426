import { ActionButtonProductType, ListedMainVariantType } from 'types/product';

export const getCatalogNumberFromProduct = (product: ActionButtonProductType): string => {
    if (product.__typename === 'MainVariant') {
        const mainVariant = product as ListedMainVariantType;
        return mainVariant.catalogNumberOriginal;
    }

    return product.catalogNumber;
};
