import Trans from 'next-translate/Trans';
import { HTMLAttributes, useState } from 'react';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import { StockOnTheWayType } from 'types/product';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { formatDateToLocaleString } from 'helpers/formaters/formatDate';
import { useDomainConfig } from 'hooks/useDomainConfig';
import twMerge from 'utils/twMerge';
import dynamic from 'next/dynamic';
import { PopupPropsType } from 'types/popup';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';

const Popup = dynamic<PopupPropsType>(
    () => import('components/Layout/Popup/Popup').then((component) => component.Popup),
    { ssr: false },
);

const TEST_IDENTIFIER = 'stock-on-the-way';

type NativeProps = ExtractNativePropsFromDefault<HTMLAttributes<HTMLDivElement>, never, 'className'>;

type StockOnTheWayProps = {
    stockOnTheWay: StockOnTheWayType[];
    textCenter?: boolean;
    isDetail?: boolean;
    showItems?: number;
    biggerFont?: boolean;
    moreInPopup?: boolean;
    productSlug?: string;
};

export const StockOnTheWay: FC<StockOnTheWayProps & NativeProps> = (props) => {
    const { defaultLocale } = useDomainConfig();
    const [isStockPopupOpen, setStockPopupVisibility] = useState(false);
    const t = useTypedTranslationFunction();

    const stockOnTheWayItems =
        props.showItems !== undefined ? props.stockOnTheWay.slice(0, props.showItems) : props.stockOnTheWay;

    if (!props.moreInPopup) {
        return (
            <>
                {/* we want display multiple lines */}
                {
                    <div
                        className={twMerge(
                            'flex flex-col font-base',
                            props.textCenter && 'items-center text-center',
                            props.className,
                        )}
                        data-testid={TEST_IDENTIFIER}
                    >
                        {props.isDetail &&
                            stockOnTheWayItems.map((stockOnTheWayItem, index) => (
                                <div
                                    className="mb-3 flex flex-row flex-wrap text-small leading-[18px] text-green [&_strong]:px-1"
                                    key={index}
                                >
                                    <Trans
                                        i18nKey="WeWillStockNext"
                                        values={{
                                            quantity: stockOnTheWayItem.quantity,
                                            date: formatDateToLocaleString(
                                                stockOnTheWayItem.deliveryDate,
                                                defaultLocale,
                                            ),
                                            unitName: stockOnTheWayItem.unitName,
                                        }}
                                        components={{
                                            strong: <strong />,
                                            1: <span />,
                                        }}
                                        defaultTrans="We will stock next <1><strong>{{ quantity }} {{ unitName }}</strong> at {{ date }}</1>"
                                    />
                                </div>
                            ))}

                        {!props.isDetail &&
                            stockOnTheWayItems.map((stockOnTheWayItem, index) => (
                                <div
                                    key={index}
                                    className={twMerge(
                                        'flex flex-row flex-wrap text-extraVerySmall [&_span]:px-1',
                                        props.biggerFont && 'text-small',
                                    )}
                                >
                                    <Trans
                                        i18nKey="WeWillStock"
                                        values={{
                                            quantity: stockOnTheWayItem.quantity,
                                            date: formatDateToLocaleString(
                                                stockOnTheWayItem.deliveryDate,
                                                defaultLocale,
                                            ),
                                            unitName: stockOnTheWayItem.unitName,
                                        }}
                                        defaultTrans="We will stock {{ date }} - {{ quantity }}&nbsp;{{ unitName }}"
                                    />
                                </div>
                            ))}
                    </div>
                }
            </>
        );
    }

    const totalStockOnTheWay = stockOnTheWayItems.reduce(
        (previousValue, currentValue) => previousValue + currentValue.quantity,
        0,
    );

    const text = (
        <Trans
            i18nKey="show stocking dates for <1>the next {{ totalStockOnTheWay }} {{ unitName }}</1>"
            defaultTrans="show stocking dates for <1>the next {{ totalStockOnTheWay }} {{ unitName }}</1>"
            values={{
                totalStockOnTheWay,
                unitName: stockOnTheWayItems[0]?.unitName,
            }}
            components={{ strong: <strong />, 1: <span /> }}
        />
    );

    return (
        <>
            {/* we want display multiple lines */}
            {
                <>
                    {/* we want display one link and popup */}
                    <div
                        className={twMerge(
                            'flex flex-col font-base',
                            props.textCenter && 'items-center text-center',
                            props.className,
                        )}
                        data-testid={TEST_IDENTIFIER}
                    >
                        {/* if only one line dont show popup link */}
                        {stockOnTheWayItems.length < 2 ? (
                            stockOnTheWayItems.map((stockOnTheWayItem, index) => {
                                const text = (
                                    <Trans
                                        i18nKey="WeWillStock"
                                        values={{
                                            quantity: stockOnTheWayItem.quantity,
                                            date: formatDateToLocaleString(
                                                stockOnTheWayItem.deliveryDate,
                                                defaultLocale,
                                            ),
                                            unitName: stockOnTheWayItem.unitName,
                                        }}
                                        defaultTrans="We will stock {{ date }} - {{ quantity }}&nbsp;{{ unitName }}"
                                    />
                                );

                                return props.productSlug ? (
                                    <ExtendedNextLink
                                        type="product"
                                        href={props.productSlug}
                                        className={twMerge(
                                            'text-extraVerySmall leading-4 [&_span]:whitespace-nowrap [&_span]:break-keep',
                                            props.isDetail && 'text-small leading-[18px]',
                                        )}
                                    >
                                        {text}
                                    </ExtendedNextLink>
                                ) : (
                                    <div
                                        className={twMerge(
                                            'text-extraVerySmall leading-4 [&_span]:whitespace-nowrap [&_span]:break-keep',
                                            props.isDetail && 'text-small leading-[18px]',
                                            props.className,
                                        )}
                                        key={index}
                                    >
                                        {text}
                                    </div>
                                );
                            })
                        ) : props.productSlug ? (
                            <ExtendedNextLink
                                type="product"
                                href={props.productSlug}
                                className={twMerge(
                                    'cursor-pointer text-extraVerySmall leading-4 underline [&_span]:whitespace-nowrap [&_span]:break-keep',
                                    props.isDetail && 'text-small leading-[18px]',
                                    props.className,
                                )}
                            >
                                {text}
                            </ExtendedNextLink>
                        ) : (
                            <div
                                className={twMerge(
                                    'cursor-pointer text-extraVerySmall leading-4 underline [&_span]:whitespace-nowrap [&_span]:break-keep',
                                    props.isDetail && 'text-small leading-[18px]',
                                )}
                                onClick={() => setStockPopupVisibility(true)}
                            >
                                {text}
                            </div>
                        )}
                    </div>
                    {isStockPopupOpen && (
                        <Popup onCloseCallback={() => setStockPopupVisibility(false)}>
                            <h3 data-testid="basic-heading-h3">{t('Storage dates')}</h3>
                            <StockOnTheWay stockOnTheWay={stockOnTheWayItems} isDetail />
                        </Popup>
                    )}
                </>
            }
        </>
    );
};

StockOnTheWay.displayName = 'StockOnTheWay';
